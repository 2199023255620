$primary-color: var(--primary-color);
$primary-color-rgba: var(--primary-color-rgba);
$secondary-color: var(--secondary-color);
$secondary-color-rgba: var(--secondary-color-rgba);
$primary-color-rgba: var(--third-color-rgba);
$fourth-color-rgba: var(--fourth-color-rgba);
$third-color: var(--third-color);
$image-color: var(--image-color);
$image-color2: var(--image-color2);
$image-primary-color: var(--image-primary-color);
$image-disabled-color: var(--image-disabled-color);
$image-logo: var(--image-logo);
$search-color: var(--search-color);
$search-image-color: var(--search-image-color);
$font-primary-color: var(--font-primary-color);
$font-secondary-color: var(--font-secondary-color);
$font-placeholder-color: var(--font-placeholder-color);
$font-disabled-color: var(--font-disabled-color);
$font-links-color: var(--font-links-color);

* {
  font-family: 'Roboto Regular';
}

/* html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
} */

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

#fog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

#fog:before,
#fog:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 300%;
  background-size: contain;
  background-position: center;
  background-repeat: repeat-x;
  /*z-index: 2;*/
}

#fog:before {
  background-image: url("images/fog-1.png");
  animation: drift 60s linear 0s infinite;
}

#fog:after {
  background-image: url("images/fog-2.png");
  animation: drift 25s linear 0s infinite;
}

@keyframes drift {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-200vw, 0, 0);
  }
}

.light_italic {
  font-family: "Roboto LightItalic";
}

.light {
  font-family: "Roboto Light";
}
/*
.regular {
    font-family: 'Roboto Regular';
}*/

.bold {
  font-family: "Roboto Bold";
}

.bold_italic {
  font-family: "Roboto Bold Italic";
}

.italic {
  font-family: "Roboto Italic";
}

.thin {
  font-family: "roboto thin";
}

.regular strong {
  font-family: "Roboto Bold";
}

body {
  letter-spacing: normal;
  color: $font-primary-color;
}

.thin_italic {
  font-family: "roboto thin";
  font-style: italic;
}

.wrap {
  background-image: url("images/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: -2;
  top: 0;
}

.content.page {
  background-color: #FFF;
}

.logo {
  display: block;
  //background-image: url("images/logo.png");
  background-image: $image-logo;
  //height: 66px;
  width: 100%;
  max-width: 148px;
  min-height: 30px;
  max-height: 60px;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 50px;
  margin-left: 50px;
  position: absolute;
  //z-index: 10;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.content {
  width: 100%;
  /*z-index: 9999999;*/
  z-index: 2;
}

.header {
  display: flex;
  height: 80px;
  padding: 0px 30px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-bottom: 1px solid #E3E4E6;
}

h1 {
  color: #fff;
  text-align: center;
  display: block;
  margin-top: 50px;
  margin-bottom: 50px;
}

.content.page h1 {
  text-align: left;
  width: auto;
  display: inline-block;
  color: $primary-color;
  margin-top: 0px;
  margin-bottom: 0px;
}

.button {
  display: inline-block;
  color: #fff;
  border: #fff solid 2px;
  padding: 10px;
  text-align: center;
  min-width: 85px;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  color: $primary-color;
  border: $primary-color solid 2px;
  text-decoration: none;
}

.login {
  float: right;
}

a:hover {
  text-decoration: none;
}

.box {
  min-height: 269px;
  background-color: $primary-color;
  color: #fff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  margin-bottom: 50px;
}

a:hover .box {
  margin-top: 10px;
}

.box .tt {
  letter-spacing: 0.5px;
  padding-left: 18px;
  padding-right: 18px;
}

.box .text {
  letter-spacing: 1px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 18px;
  padding-bottom: 18px;
}

.box .more {
  letter-spacing: 1px;
  background-color: #fff;
  color: $primary-color !important;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 18px;
  padding-bottom: 18px;
}

.box .more i {
  display: inline-block;
  float: right;
  width: 8px;
  height: 12px;
  background-image: url("images/selector.png");
  background-size: 8px 12px;
  background-position: right;
  background-repeat: no-repeat;
}

.box .icon {
  display: block;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;
  margin-top: 30px;
  margin-left: 18px;
}

.box .icon.icon-01 {
  background-image: url("images/icon-01.png");
}

.box .icon.icon-02 {
  background-image: url("images/icon-02.png");
}

.box .icon.icon-03 {
  background-image: url("images/icon-03.png");
}

.box .icon.icon-04 {
  background-image: url("images/icon-04.png");
}

.box1 {
  background-color: $primary-color;
}

.box2 {
  background-color: #4b78bc;
}

.box3 {
  background-color: #3e6aa3;
}

.box4 {
  background-color: #769bbd;
}

.box-seats {
  width: 100%;
  min-height: 216px;
}

.box-seats .icon {
  float: left;
  width: 53px;
  height: 82px;
  background-image: url("images/asiento.png");
  margin-right: 30px;
  margin-top: 0px;
}

.box-seats .tt {
  padding-top: 30px;
  vertical-align: middle;
}

.box-seats .tt span {
  padding-top: 25px;
  display: inline-block;
}

.box-secundary {
  background-color: #efefef;
  height: auto;
  min-height: 216px;
  color: $primary-color;
  width: 100%;
  padding-top: 40px;
  border-radius: 5px;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-fill.col-4 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.p-autocomplete-loader {
  right: 46px !important;
  top: 35% !important;
}

.copyright {
  width: 100%;
  color: #769bbd !important;
  letter-spacing: 1px;
}
.copyright a {
  font-weight: normal;
  color: #769bbd !important;
  margin-left: 50px;
  position: relative;
}

.copyright a i {
  display: inline-block;
  height: 1px;
  width: 0%;
  background-color: #769bbd;
  position: absolute;
  left: 0;
  bottom: -1px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.content.page .copyright {
  position: static;
}

.copyright a:hover i {
  width: 100%;
}

.copyright {
  color: #fff;
  margin-top: 50px;
}

.social {
  float: right;
  height: 20px;
  margin-top: 5px;
}

.social a.facebook {
  display: inline-block;
  width: 6px;
  height: 13px;
  background-image: url("images/facebook.png");
  background-repeat: no-repeat;
  margin-right: 0px;
  background-size: contain;
  margin-left: 10px;
}

.icon-sidebar {
  border-left: #fff solid 1px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: -15px;
  height: 46px;
  width: 40px;
  text-align: right;
  padding-right: 4px;
}

.bg-transparent {
  border: $primary-color solid 1px !important;
}

.social a.facebook:hover {
  background-image: url("images/facebook-hover.png");
}

.social a.twitter {
  display: inline-block;
  width: 12px;
  height: 10px;
  background-image: url("images/twitter.png");
  background-repeat: no-repeat;
  margin-right: 0px;
  background-size: contain;
  margin-left: 10px;
}

.social a.twitter:hover {
  background-image: url("images/twitter-hover.png");
}

.social a.google {
  display: inline-block;
  width: 12px;
  height: 13px;
  background-image: url("images/google.png");
  background-repeat: no-repeat;
  margin-right: 0px;
  background-size: contain;
  margin-left: 10px;
  margin-top: 2px;
}

.social a.google:hover {
  background-image: url("images/google-hover.png");
}

.menu {
  width: 29px;
  height: 14px;
  background-image: url("images/menu.png");
  background-size: contain;
  display: inline-block;
  background-repeat: no-repeat;
  float: right;
  margin-top: 30px;
  margin-right: 20px;
}

.tabpanel-white {
  font-family: 'Roboto Regular';
  background-color: #F8F8F8;
  border-radius: 8px;
  border: 1px solid #F8F8F8;
  padding: 24px 10px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10);
}
#flight-search-box.tabpanel-white {
  background-color: $primary-color !important;
  border: 1px solid $primary-color !important;
  #departure-return-labels, .checboxSection, #flight-search-box-header-title {
    color: $search-color !important;
  }
  #flight-search-box-header #flight-search-box-header-img {
    filter: $search-image-color !important;
  }
}

.tabpanel {
  /*background-image: url("images/bg_tabpanel_gris.png");
    background-repeat: no-repeat;
    background-size: cover;*/
  background-color: #e3e4e6;
  border-radius: 5px;
}
.tabpanelHome {
  background-color: #efefef !important;
}

.tabpanel .tab {
  padding: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  //color: $primary-color;
  color: $primary-color;
}

.tabpanel .title div:hover {
  background-color: $primary-color-rgba;
}

.tabpanel .title div {
  flex: 1 1 auto !important;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  background-color: $primary-color;
  color: #fff;
  //letter-spacing: 1px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.tabpanel .title > div.isTabSearch:first-child {
  border-top-left-radius: 6px;
}
.tabpanel .title div.isTabSearch {
  padding-top: 15px;
  padding-bottom: 15px;
}
.tabpanel .title > div.isTabSearch.noTabSelected {
  border-bottom: 1px solid black;
}
.tabpanel .title > div.isTabSearch.noTabSelected:first-child {
  border-right: 1px solid black;
}
.tabpanel .title > div.isTabSearch.noTabSelected:last-child {
  border-left: 1px solid black;
}
.tabpanel .title div i {
  display: inline-block;
  float: right;
  width: 15px;
  height: 8px;
  background-image: url("images/arrow_down_01.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 5px;
  transform: rotate(-90deg);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  //filter: var(--image-color);
}

.tabpanel .title div:hover i {
  transform: rotate(0deg);
}

.tabpanel .title div.active {
  background-color: transparent !important;
  color: $primary-color;
}

.tabpanel .title div.active i {
  background-image: url("images/arrow_down_00.png");
  transform: rotate(0deg);
}

.tabpanel .tab-info {
  border-top: #fff solid 2px;
  margin-bottom: -20px;
}

.tabpanel .tab-info div {
  border-right: #fff solid 1px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  flex: 1 1 auto !important;
}

.tabpanel .tab-info div:last-child {
  border-right: #fff solid 0px;
}

/*.tabpanel .tab-info div:first-child {
    padding-left: 0px;
}*/

input[type="text"]:not(.swal2-input), textarea {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: $font-primary-color;
  border: #fff solid 1px;
  //letter-spacing: 1px;
  margin-bottom: 20px;
}

input[type="text"]:focus {
  border: $primary-color solid 1px;
  outline: none;
}

input[type="number"] {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: $font-primary-color;
  border: #fff solid 1px;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

input[type="number"]:focus {
  border: $primary-color solid 1px;
  outline: none;
}

.checkbox {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  border: $primary-color solid 1px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  min-width: 100%;
  background-color: $primary-color;
  -webkit-appearance: none;
  background-image: url("images/checkbox.png");
  transform: rotate(0deg);
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
}

.checkbox.active {
  background-image: url("images/checkbox-active.png");
}

.checkbox.secundary {
  background-color: #f0f0f0;
  border: #f0f0f0 solid 1px;
  color: $primary-color;
}

hr {
  height: 1px;
  background-color: #fff;
}

.border-none {
  border-bottom: none !important;
  border-top: none !important;
}

select {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: $font-primary-color;
  border: $font-primary-color solid 1px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  min-width: 100%;
  background-color: transparent;
  -webkit-appearance: none;
  background-image: url("images/select.png");
  transform: rotate(0deg);
  background-repeat: no-repeat;
  background-position: right;
}

select:focus {
  border: $secondary-color solid 1px;
  outline: none;
}

.llegada {
  background-image: url("images/llegada.png");
  background-repeat: no-repeat;
  background-position: right;
}

.salida {
  background-image: url("images/salida.png");
  background-repeat: no-repeat;
  background-position: right;
}

.airline {
  /*background-image: url("images/plane.png");
    background-repeat: no-repeat;
    background-position: right;*/
  border: 0 !important;
  height: 100%;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 5px !important;
}

.fecha {
  text-align: let;
  padding-left: 20px !important;
  /*background-image: url("images/calendar.png");
    background-repeat: no-repeat;
    background-position: right;*/
}

.hora {
  text-align: left;
  /*padding-left: 20px;*/
  /*max-width: 150px;*/
  /*background-image: url("images/time.png");
    background-repeat: no-repeat;
    background-position: right;*/
}

.number {
  max-width: 120px;
  text-align: center;
  display: inline-block;
}

a.button.counter {
  display: inline-block;
  height: 41px;
  width: 41px;
  margin-bottom: 20px;
  cursor: pointer;
  /*line-height: 16px;*/
  text-align: center;
  font-size: 1.3em;
  min-width: 41px !important;
  margin-right: 10px;
  margin-left: 10px;
  background-color: $primary-color;
  color: #fff;
  border: $primary-color solid 2px;

  background-image: url("images/sum.png");
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  text-indent: -9999px;
}
a.button.counter.res {
  /*line-height: 12px;*/
  background-image: url("images/res.png");
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  /*background-size: 18px;*/
  text-indent: -9999px;
}
/*input.counterInput {
    width: 41px !important;
}*/
a.button.counter:hover {
  background-color: $secondary-color;
  border: $secondary-color solid 2px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.compareFlights {
  width: 100%;
  color: #fff;
  border: #8e969f solid 2px;
  background-color: #8e969f;
  text-align: left;
  cursor: pointer;
}

.compareFlights:hover {
  color: #fff;
  border: #8e969f solid 2px;
}
.back {
  color: #fff;
  border: #8e969f solid 2px;
  background-color: #8e969f;
  text-align: left;
  cursor: pointer;
  background-image: url("images/back.png");
  background-repeat: no-repeat;
  background-position: 10px;
  padding-left: 30px;
  padding-right: 20px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.back:hover {
  color: #fff;
  border: #8e969f solid 2px;
}

.btn-gray {
  color: #fff;
  border: #8e969f solid 2px;
  background-color: #8e969f;
  text-align: left;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 11px;
  padding-bottom: 11px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
}

.btn-gray:hover {
  color: #fff;
  border: #8e969f solid 2px;
}
.loyaltyProgram {
  display: grid;
  grid-template-columns: 1fr 1fr max-content;
  height: fit-content;
  gap: 5px;
  align-items: baseline;
}
.loyaltyProgramRemove {
  cursor: pointer;
}
.ngb-dp-weekday {
  font-family: 'Roboto Regular' !important;
}
.select-flight {
  width: 100%;
  color: #fff;
  border: $secondary-color solid 2px;
  background-color: $secondary-color;
  text-align: left;
  cursor: pointer;
  background-image: url("images/search.png");
  background-repeat: no-repeat;
  background-position: 96% 47%;
  background-origin: border-box;
  min-width: 200px;
  padding: 10px 16px;
}
.select-flight-comparator {
  width: 100%;
  color: #fff;
  border: 0;
  background-color: $secondary-color;
  text-align: center;
  cursor: pointer;
  width: 100%;
  padding: 16px;
  border-radius: 0 !important;
  background-image: url("images/search.png");
  background-repeat: no-repeat;
  background-position: 96% 47%;
  background-origin: border-box;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.select-flight-comparator:hover,
.select-flight-to-comparator:hover,
.clear-selections:hover {
  border: 0 !important;
}
.select-flight-to-comparator {
  color: #fff;
  border: 0;
  background-color: $secondary-color;
  text-align: center;
  cursor: pointer;
  padding: 16px;
  width: fit-content;
}
.select-flight-to-comparator.disabled {
  background-color: rgb(97, 97, 97);
}
.clear-selections {
  color: #fff;
  border: 0;
  background-color: transparent;
  text-align: center;
  cursor: pointer;
  width: fit-content;
}
.clear-selections:after {
  content: "\1F5D9";
  display: inline-block;
  vertical-align: super;
  margin-left: 3px;
}
.box-flight-compare-close::before {
  content: "\1F5D9";
  display: block;
  text-align: end;
  cursor: pointer;
}
.clearCompareSelections {
  height: 100%;
}
#modalFlightComparator {
  background-color: #2f2f2f;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
}
.buttonBGGray {
  background-color: #8e969f !important;
  border-color: #8e969f !important;
}
.orangeButton {
  width: 100%;
  color: #fff;
  border: $secondary-color solid 2px;
  background-color: $secondary-color;
  text-align: left;
  cursor: pointer;
  min-width: 0px !important;
  text-align: center;
}
.search {
  width: 100%;
  color: #FFF;
  border: #f47025 solid 2px;
  background-color: #f47025;
  text-align: left;
  cursor: pointer;
  background-image: url("images/search.png");
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
  /*padding-right: 18px;*/
}
.search:hover,
.orangeButton:hover {
  color: #fff;
  border: $secondary-color solid 2px;
}

.button.action {
  color: #fff;
  border: $secondary-color solid 2px;
  background-color: $secondary-color;
  text-align: center;
  cursor: pointer;
  /*background-image: url("images/search.png");*/
  background-repeat: no-repeat;
  background-position: right;
  /*padding-right: 28px;*/
}

.button.action.secundary {
  background-color: #8e969f;
  border: #8e969f solid 2px;
}

.button.more {
  background-color: #e3e4e6;
  border: #e3e4e6 solid 2px;
  width: 100%;
  color: $primary-color;
  cursor: pointer;
}

.button.more i {
  display: inline-block;
  width: 15px;
  height: 8px;
  background-image: url("images/arrow_down_00.png");
  margin-left: 20px;
}

.white {
  color: #fff !important;
}

.button.action:hover {
  color: #fff;
  border: $secondary-color solid 2px;
}

.button.action.secundary:hover {
  color: #fff;
  border: #8e969f solid 2px;
}

::-webkit-input-placeholder {
  /* Edge */
  color: $font-placeholder-color;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $primary-color;
}

::placeholder {
  color: $font-placeholder-color;
}

.box-resume {
  background-color: $primary-color;
  color: #fff;
  letter-spacing: 1px;
  border-radius: 5px;
  width: 100%;
}

.box-resume div {
  border-right: #1c293a solid 1px;
  padding: 20px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.box-resume div:last-child {
  border-right: #1c293a solid 0px;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.flight-resume {
  background-color: #fff;
  border-radius: 5px;
  color: $primary-color;
  //margin-bottom: 20px;
}

.flight-resume .flight-01 {
  border-bottom: #e3e4e6 solid 1px;
  padding-top: 20px;
  padding-bottom: 20px;
  /*padding-left: 20px;
    padding-right: 20px;*/
}

.flight-resume .flight-02 {
  border-bottom: #e3e4e6 solid 1px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.flight-resume .alert {
  background-color: #9670b4;
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 0px;
}

.flight-resume .links {
  color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0 px;
  padding-right: 20px;
}

.flight-resume .links a {
  display: inline-block;
  padding-right: 20px;
  color: $primary-color;
  text-transform: uppercase;
  cursor: pointer;
}

.flight-resume .links a i,
.selector2 {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background-image: url("images/selector.png");
  background-size: 6px 10px;
  background-repeat: no-repeat;
  background-position: right;
  transform: rotate(90deg);

  display: inline-block;
  width: 8px;
  height: 12px;
  margin-left: 5px;
  margin-bottom: -1px;
  filter: var(--image-color);
}

.flight-resume .links a:hover i,
.selector2:hover {
  transform: rotate(0deg);
}
.flight-resume .price-details {
  background-color: #f8f8f8;
}
.row .row {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.radius-none {
  border-radius: 0px;
}

.cancel {
  color: $primary-color;
}

.cancel img {
  margin-bottom: -1px;
}

.box-info {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
}

.box-info .tt {
  padding-left: 20px;
  border-bottom: #e3e4e6 solid 1px;
  padding-bottom: 20px;
}

.box-info .list {
  padding-left: 20px;
  border-bottom: #e3e4e6 solid 1px;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-right: 20px;
}

.box-info .list .item {
  display: block;
  width: 100%;
  flex: 1 1 auto !important;
  background-color: #f8f8f8;
  margin-bottom: 5px;
  padding: 5px;
}

.box-info .list .item a {
  text-decoration: underline;
  color: $primary-color;
}

.box-info .list .item:last-child {
  margin-bottom: 0px;
}

.box-info .total {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.payment-resume {
  margin-top: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: $primary-color solid 1px;
  border-bottom: $primary-color solid 1px;
}

.tab .list {
  padding-left: 20px;
  border-bottom: #e3e4e6 solid 1px;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-right: 20px;
}

.tab .list .item {
  display: block;
  width: 100%;
  flex: 1 1 auto !important;
  background-color: #f8f8f8;
  margin-bottom: 5px;
  padding: 5px;
}

.tab .list .item a {
  text-decoration: underline;
  color: $primary-color;
}

.tab .list .item:last-child {
  margin-bottom: 0px;
}

.box-resume input {
  display: inline-block;
  width: auto;
  background-color: transparent;
  border: none;
  padding-right: 30px;
  color: #fff !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.border-radius-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.orange {
  color: #f47025;
}

.border-radius {
  border-radius: 5px;
}

.bg-warning {
  background-color: $secondary-color !important;
  color: #fff;
}

.bg-primary {
  background-color: $primary-color !important;
  color: #fff;
}

.bg-secondary {
  background-color: #efefef !important;
  color: $primary-color;
}
.c-primary {
  color: $primary-color !important;
}

.c-secondary {
  color: $secondary-color !important;
}
.b-primary {
  border-color: $primary-color !important;
}
.box-resume input:focus {
  border: none;
}

.box-resume input::-webkit-input-placeholder {
  /* Edge */
  color: #fff !important;
}

.box-resume input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff !important;
}

.box-resume input::placeholder {
  color: #fff !important;
}

.icon-danger {
  margin-bottom: -5px;
  margin-right: 10px;
}

.box-img {
  background-color: #fff;
  min-height: 80px;
}

.range {
  position: relative;
}

.range .bar {
  display: block;
  height: 4px;
  background-color: #fff;
  margin-bottom: 10px;
}

.range .bar .percent {
  display: block;
  height: 4px;
  width: 0px;
  background-color: $primary-color;
}

.range .init {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: $secondary-color;
  top: -8px;
  cursor: pointer;
}

.range .end {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: $secondary-color;
  top: -8px;
  left: 0;
  cursor: pointer;
}

.date-multiple,
.date-single {
  display: block;
  background-color: #ffffff;
  position: absolute;
  z-index: 9999;
  width: calc(100% - 30px);
  padding-bottom: 10px;
}

.input-list {
  display: none;
  background-color: #ffffff;
  position: absolute;
  z-index: 99999;
  width: calc(100% - 30px);
  color: $primary-color;
  border-radius: 5px;
  margin-top: -15px;
}

.input-list.visible {
  display: block;
}

.input-list .label {
  display: block;
  color: $primary-color;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #fff;
  padding-left: 15px;
}

.input-list .item {
  display: block;
  color: $primary-color;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  border-bottom: #e3e4e6 solid 1px;
  cursor: pointer;
}

.input-list .item:hover {
  background-color: #f6f6f6;
}

.input-list .item:last-child {
  border-bottom: #e3e4e6 solid 0px;
}

.calendar {
  clear: both;
}

.calendar .dw {
  float: left;
  width: 14.28%;
  display: block;
  text-align: center;
  border: #fff solid 1px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.calendar .days a {
  float: left;
  width: 14.28%;
  display: block;
  text-align: center;
  background-color: #f8f8f8;
  border: #fff solid 1px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #b2b2b2;
  //color: $primary-color;
}

.hourSelected {
  color: #ffffff !important;
  background-color: $secondary-color !important;
}

.calendar .days a:hover {
  //background-color: #ffc19d !important;
  background-color: $secondary-color-rgba !important;
}

.month .month-title {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  float: left;
  margin-top: 20px;
}

.date-multiple {
  display: none;
  border-radius: 5px;
  margin-top: 5px;
}

.date-multiple.active {
  display: block;
}

.date-multiple .dot {
  display: block;
  width: 47px;
  height: 18px;
  background-image: url("images/dot.png");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  margin-top: -17px;
  margin-left: 50px;
}

.label {
  margin-left: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.month {
  display: block;
  border-top: #e3e4e6 solid 1px;
  border-bottom: #e3e4e6 solid 1px;
}

.month .prev {
  display: block;
  background-color: $secondary-color;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
  background-image: url("images/prev.png");
  background-repeat: no-repeat;
  background-position: center;
}

.month .next {
  display: block;
  background-color: $secondary-color;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  background-image: url("images/next.png");
  background-repeat: no-repeat;
  background-position: center;
}

.calendar .days a.active {
  background-color: $secondary-color;
  color: #fff;
}

.calendar .days {
  display: block;
  width: 100%;
  clear: both;
}

.date-single {
  max-width: 236px;
  /*margin-left: 190px;*/
  padding: 10px;
  border-radius: 5px;
  margin-top: -10px;
  text-align: center;
  display: none;
}

.date-single.active,
.input-list.active {
  display: block;
  min-width: 230px;
}

.button.confirm {
  background-color: $secondary-color;
  border-color: $secondary-color;
  color: #fff !important;
  margin-top: 10px;
  width: 80%;
}

.date-single .checkbox {
  background-position: 10px;
  background-size: 30px;
  background-color: #f8f8f8;
  border: none;
  color: $primary-color;
}

.input-list .item.flight {
  background-image: url("images/icon-plane-select.png");
  background-size: 27px 25px;
  background-repeat: no-repeat;
  background-position: 10px 10px;
  padding-left: 50px;
}

.input-list .item.world {
  background-image: url("images/icon-world-select.png");
  background-size: 27px 25px;
  background-repeat: no-repeat;
  background-position: 10px 10px;
  padding-left: 50px;
}
//////////////////
input[type="checkbox"]:focus {
  outline: none;
}

/*.item input[type="checkbox"] {
    display: inline-block;
    width: 12px;
    height: 12px;
    -webkit-appearance: none;
    background-image: url("images/checkbox-outside.png");
    transform: rotate(0deg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    cursor: pointer;
    margin-bottom: 0px;
    margin-left: 10px;
}

.item input[type="checkbox"]:checked {
    background-image: url("images/checkbox-outside.png");
}*/
////////////
.labelcheckboxCustom {
  display: table;
  width: 100%;
  padding: 5px 10px !important;
  border-radius: 5px;
  border: none;
  color: #fff;
  border: $primary-color solid 1px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  min-width: 100%;
  background-color: $primary-color;
  -webkit-appearance: none;
  transform: rotate(0deg);
  background-repeat: no-repeat;
  background-position: right;
  cursor: pointer;
}

.labelcheckboxCustom input[type="checkbox"],
.labelcheckboxCustom input[type="radio"],
input[type="checkbox"] {
  border: 0;
  display: table-cell;
  width: 28px;
  height: 28px;
  -webkit-appearance: none;
  background-color: transparent;
  background-image: url("images/checkbox-outside-white-checked.png");
  background-size: 22px;
  transform: rotate(0deg);
  background-repeat: no-repeat;
  background-position: 78% 55%;
  cursor: pointer;
  margin-bottom: -3px;
  //margin-left: 10px;
  vertical-align: middle;
  //filter: $checkbox1-image-color;
}
input[type="checkbox"].brandedFaresCB,
input[type="checkbox"].compareFlightsCB {
  background-image: url("images/checkbox-outside-checked.png");
}
input[type="checkbox"]:checked.brandedFaresCB,
input[type="checkbox"]:checked.compareFlightsCB {
  background-image: url("images/checkbox-outside-checked.png");
  outline: none;
}
input[type="checkbox"] {
  display: inline-block;
}
input[type="checkbox"].cbwhite {
  display: inline-block;
  /*background-image: url("images/checkbox-outside-white.png") !important;*/
}
input[type="checkbox"].cbwhite:checked {
  display: inline-block;
  background-image: url("images/checkbox-outside-white-checked.png") !important;
}
input[type="checkbox"].checkbox2 {
  display: inline-block;
}
.tablerow {
  display: table-row;
}

.labelcheckboxCustom input[type="checkbox"]:checked,
.labelcheckboxCustom input[type="radio"]:checked,
input[type="checkbox"]:checked {
  background-image: url("images/checkbox-outside-white-checked.png");
  outline: none;
}
.labelcheckboxCustom input[type="checkbox"]:checked:after,
input[type="checkbox"]:checked:after {
  position: absolute;
  filter: $image-color;
  width: 100%;
  height: 100%;
  content: "";
  background-image: url("images/checkbox-inside.png");
  background-repeat: no-repeat;
  top: 4px;
  left: 5px;
  background-size: 20px;
}
#extraServiceCheck:checked:after {
  left: 6px;
}
input[type="checkbox"].compareFlightsCB:checked:after {
  top: 5px;
  left: 6px;
  background-size: 18px;
}
.labelcheckboxCustom input[type="checkbox"]:focus,
.labelcheckboxCustom input[type="radio"]:focus {
  outline: none;
}

.labelcheckboxCustom label {
  display: table-cell;
  vertical-align: middle;
}
input[type="checkbox"].checkbox-list {
  border: 0;
  display: table-cell;
  min-width: 20px;
  width: 20px;
  height: 21px;
  -webkit-appearance: none;
  background-color: transparent;
  background-image: url("images/checkbox-list.png");
  background-size: 19px;
  transform: rotate(0deg);
  background-repeat: no-repeat;
  background-position: 78% 55%;
  cursor: pointer;
  margin-bottom: -3px;
  margin-left: 10px;
  vertical-align: middle;
}
input[type="checkbox"].checkbox-list:checked {
  background-image: url("images/checkbox-list-checked.png");
  outline: none;
}
input[type="checkbox"].checkbox-list:checked:after {
  top: 1px;
  left: 1px;
  background-size: 19px;
}
.error {
  border: 1px solid red !important;
}
/* PASSENGERS */
#iconShowPassenger {
  height: 21px;
  left: 8px;
  top: 10px;
  letter-spacing: 1px;
}
#showPasssenger {
  margin-left: 10px;
  line-height: 38px;
}
.custom_arrow {
  top: 40%;
  right: 0;
  margin-top: -0.5em;
  margin-left: -0.5em;
  position: absolute;
  color: var(--primary-color) !important;
  width: 2.5em;
  line-height: 2em;
  text-align: center;
  padding: 0;
}
.custom_arrow:before {
  content: "\f0d7";
  display: inline-block;
  font: normal normal normal 24px/1 FontAwesome;
}
#passengerBoxx {
  background-color: white;
  //border: 1px solid #ced4da;
  border: 0 !important;
  border-radius: 0.25rem;
  height: 100%;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  position: relative;
  cursor: pointer;
}
#passenger label {
  margin: 0;
  font-weight: 500;
}

#passengerModal {
  cursor: default;
  background-color: white;
  display: block;
  position: absolute;
  top:-550%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 24px;
  width: 100%;
  min-width: 250px;
  z-index: 1000;
  border-radius: 16px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 3px 0px color(display-p3 0 0 0 / 0.30), 0px 4px 8px 3px color(display-p3 0 0 0 / 0.15);
}

#passengerModal .input-group {
  margin-bottom: 16px;
  align-items: center;
  input {
    outline: none;
    background-color: #fff !important;
    border: 1px solid #E3E4E6;
    flex-grow: 1;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Roboto Regular';
    color: $font-primary-color;
  }
}

#passengerModal:focus, #passengerModal:focus-visible {
  outline: none;
}

.passengerSectionModal {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 16px;
  align-items: center;
}

.labelDescription {
  width: 100%;
  .passengerLabel {
    font-size: 16px;
    line-height: 20px;
    font-weight: bolder;
    span {
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;
    }
  }
}

.labelDescription label,
.labelDescription span {
  display: block;
}


.number-input input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.number-input input[type="number"]::-webkit-inner-spin-button,
.number-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  display: flex;
  align-items: center;
  gap: 15px;
}

.number-input span {
  font-size: 16px;
  line-height: 20px;
  font-weight: bolder;
}

.number-input,
.number-input * {
  box-sizing: border-box;
}

.number-input div {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border-radius: 25px;
  border: 1px solid var(--primary-color);
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin: auto;
  position: relative;

  /*min-width: 28px;
    width: 28px;
    height: 28px;*/
}

.number-input div:before,
.number-input div:after {
  display: inline-block;
  position: absolute;
  content: "";
  width: 0.7rem;
  height: 1.8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary-color);
  /* #5c5c5c*/
}

.number-input div.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.number-input div:hover {
  background-color: var(--primary-color) !important;
}
.number-input div.disabled {
  background-color: #F7F9FC;
  border-color: #E9EDF5;
  cursor: default;
  &:after, &:before {
    background-color: #BFC6DC !important;
  }
  &:hover {
    background-color: #F7F9FC !important;
  }
}
.number-input div:hover:before,
.number-input div:hover:after,
.number-input div.plus:hover:after {
  background-color: white !important;
}
.number-input div.disabled:hover:before,
.number-input div.disabled:hover:after,
.number-input div.disabled.plus:hover:after {
  background-color: #BFC6DC !important;
}

.number-input input[type="number"] {
  font-family: "Roboto Bold";
  max-width: 2rem;
  padding: 0;
  border: 0;
  /*font-size: 1.2rem;*/
  height: 2rem;
  text-align: center;
  border-radius: 0;
  margin: 0 3px;
  font-size: 25px;
  min-width: 2rem;
}
.passengerDates {
  display: flex;
  align-items: center;
  & > span {
    font-size: 14px;
    line-height: 20px;
    margin-right: 16px;
  }
  & > img {
    cursor: pointer;
    width: 12px;
  }
}
/* END PASSENGERS */
.fecha.color-error::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $secondary-color !important;
}

.fecha.color-error::-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $secondary-color !important;
  opacity: 1;
}

.fecha.color-error::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $secondary-color !important;
  opacity: 1;
}

.fecha.color-error::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $secondary-color !important;
}

.selectMultiple {
  border: 0 !important;
  outline: 0 !important;
  width: auto !important;
  margin: 0px !important;
  padding: 0px !important;
  flex-grow: 1;
}

.selected {
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  margin-top: 3px;
  /*padding:2px;*/
  display: inline-table !important;
  cursor: auto;
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  font-family: "Roboto Regular";
  font-size: 14px;
}

.close-selected {
  margin-left: 0.3rem;
  cursor: pointer;
}

.tagWrapperAirline {
  width: calc(100% - 41px);
  display: flex;
  flex-wrap: wrap;
}

.wrapAirline {
  justify-content: left;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

#airlineTag .dropdown-item {
  padding-left: 10px !important;
  display: flex !important;
  align-items: center !important;
  color: $font-primary-color !important;
  padding-top: 10px !important;
  padding-bottom: 3px !important;
  cursor: pointer !important;
  font-family: "Roboto Regular" !important;
  font-size: 14px !important;
  background-color: #fff !important;
}

#airlineTag .dropdown-item:hover {
  color: $primary-color;
  background-color: #f6f6f6;
}

#airlineTag .dropdown-item.active,
.dropdown-item:active {
  color: $primary-color;
  background-color: #f6f6f6;
}

#airlineTag .dropdown-item:last-of-type {
  border-bottom: 0px !important;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  margin: 10px 0;
  background-color: transparent;
}

@supports (-ms-ime-align: auto) {
  .slider {
    min-height: 22px;
  }
}

.slider:focus {
  outline: none;
}

.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  box-shadow: 0px 0px 1px #000000, 0px 0px 0px #0d0d0d;
  background: $primary-color;
  border-radius: 2.6px;
  border: 0.2px solid rgba(0, 0, 0, 0);
}

.slider::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  border: 10px solid $secondary-color;
  height: 20px;
  width: 20px;
  border-radius: 12px;
  background: rgba(244, 112, 37, 0.66);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8.1px;
}

.slider:focus::-webkit-slider-runnable-track {
  background: #305291;
}

.slider::-moz-range-track {
  width: 100%;
  height: 2.2px;
  cursor: pointer;
  box-shadow: 0px 0px 1px #000000, 0px 0px 0px #0d0d0d;
  background: $primary-color;
  border-radius: 2.6px;
  /*border: 0.2px solid rgba(0, 0, 0, 0);*/
}

.slider::-moz-range-thumb {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  border: 7px solid $secondary-color;
  height: 8px;
  width: 8px;
  border-radius: 12px;
  background: rgba(244, 112, 37, 0.66);
  background-color: $secondary-color;
  cursor: pointer;
}

.slider::-ms-track {
  width: 100%;
  height: 4.2px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.slider::-ms-fill-lower {
  background: #2a4a81;
  border: 0.2px solid rgba(0, 0, 0, 0);
  border-radius: 5.2px;
  box-shadow: 0px 0px 1px #000000, 0px 0px 0px #0d0d0d;
}

.slider::-ms-fill-upper {
  background: #ffffff;
  border: 0.2px solid rgba(0, 0, 0, 0);
  border-radius: 5.2px;
  box-shadow: 0px 0px 1px #000000, 0px 0px 0px #0d0d0d;
}

.slider:focus::-ms-fill-lower {
  background: $primary-color;
}

.slider:focus::-ms-fill-upper {
  background: #ffffff;
}

.slider::-ms-thumb {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0), 0px 0px 1px rgba(13, 13, 13, 0);
  border: 7px solid $secondary-color;
  height: 7px;
  width: 7px;
  border-radius: 12px;
  background: rgba(244, 112, 37, 0.66);
  background-color: $secondary-color;
  cursor: pointer;
  margin-top: 0px;
  /*/height: 4.2px;*/
}

.searchCol {
  padding: 0 !important;
  background-color: transparent !important;
}

.airlineSearchCol {
  background-color: transparent !important;
}

.tabSearchCol {
  padding-top: 0 !important;
  padding-bottom: 20px !important;
}

.ml-3-45percent {
  width: 45%;
}

.ml-3-35percent {
  width: 45%;
}

.width50percent {
  width: 50%;
  position: relative;
}

.searchDep {
  margin-bottom: 20px;
  position: relative !important;
}
.iconsColor {
  filter: $image-color;
}
.iconsColor2 {
  filter: $image-color2;
}
.searchIcons {
  position: absolute;
  top: 13px;
  right: 0;
  //filter: invert(55%) sepia(100%) saturate(5107%) hue-rotate(203deg) brightness(95%) contrast(90%);
  filter: $image-color;
}
#salida ~ .searchIcons {
  right: 13px !important;
}
#llegada ~ .searchIcons {
  right: 13px !important;
}
.airline ~ .searchIcons {
  right: 13px !important;
}
.dob.searchIcons {
  right: 7px !important;
}
.cancelBtn img {
  top: -2px !important;
}
.fecha::-webkit-input-placeholder,
.hora::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $primary-color;
}

.fecha:-moz-placeholder,
.hora:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $primary-color;
  opacity: 1;
}

.fecha::-moz-placeholder,
.hora::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $primary-color;
  opacity: 1;
}

.fecha:-ms-input-placeholder,
.hora:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $primary-color;
}

.lineSlider {
  width: 100%;
  height: 6px;
  background-color: $primary-color;
  position: relative;
  float: left;
  text-align: center;
  margin-top: 10px;
}

/*select.custom-select {
	margin: 0.5rem 0.5rem 0 0;
	width: auto;
}*/

.ngb-dp-weekday {
  color: #868FA0 !important;
  font-style: normal !important;
  width: 2.2rem !important;
  height: 2rem !important;
  font-family: "Roboto";
  font-size: 11.4px;
}

.ngb-dp-arrow .ngb-dp-arrow-btn {
  display: block !important;
  background-color: #F7F9FC !important;
  border: 1.66667px solid #DEE0E3 !important;
  border-radius: 10px !important;
  width: 35px !important;
  height: 30px !important;
  float: left !important;
  /*margin-top: 10px !important;
    margin-bottom: 10px !important;*/
  background-image: url("images/prev_month.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 8px 10px;
}

.ngb-dp-arrow.right .ngb-dp-arrow-btn {
  display: block !important;
  background-color: #F7F9FC !important;
  border: 1.66667px solid #DEE0E3 !important;
  border-radius: 10px !important;
  width: 35px !important;
  height: 30px !important;
  float: right !important;
  /*margin-top: 10px !important;
    margin-bottom: 10px !important;*/
  background-image: url("images/next_month.svg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 8px 10px;
}

.ngb-dp-navigation-chevron {
  display: none !important;
}

.dropdown-menu.show.ng-star-inserted {
    z-index: 9999!important;
    padding: 5px!important;
}
ngb-datepicker.dropdown-menu.show.ng-star-inserted {
  padding: 24px 16px !important;
  border-radius: 4px;
  box-shadow: 0px 0px 0px 0.5px rgba(255, 255, 255, 0.4), 0px 15px 35px -5px rgba(17, 24, 38, 0.5), 0px 5px 15px rgba(0, 0, 0, 0.25);
}
/* .ngb-dp-header::before {
  content: attr(data-before);
  width: 100%;
  background-color: $primary-color;
  color: white;
  font-family: "Roboto Bold";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px 14px;
  font-size: 17px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
} */

.ngb-dp-header {
  background-color: #ffffff !important;
  border-top: 0 !important;
  margin-top: 5px;
  border-radius: 0px !important;
}

ngb-datepicker-navigation-select select {
  cursor: pointer;
  text-align: center;
  background-image: none;
}

.ngb-dp-months {
  @media (max-width: 650px) {
    display: flex;
    flex-direction: column;
  } 
}

.p-sidebar-content #datepickerRange .ngb-dp-months {
  flex-direction: column !important;
}

.ngb-dp-month {
  display: flex;
  flex-direction: column;
}

.ngb-dp-month-name {
  background-color: #ffffff !important;
  color: $font-primary-color !important;
  display: block !important;
  margin-bottom: 12px !important;
  margin-top: 12px !important;
  font-family: "Roboto Regular" !important;
  font-size: 14px !important;
  /*text-transform: uppercase !important;*/
  float: right !important;
  height: 1rem !important;
  line-height: 1rem !important;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.ngb-dp-weekdays {
  background-color: #ffffff !important;
  text-transform: uppercase !important;
  border-top: 0 !important;
  border-bottom: 0px !important;
  font-family: "Roboto Bold" !important;
  font-size: 12px;
}
.ngb-dp-week {
  margin: 3px 0;
}
.ngb-dp-day {
  /*width: 14.28%!important;*/
  text-align: center !important;
  background-color: transparent !important;
  border: none !important;
  /*color: #b7b7b7!important;*/
  color: $font-primary-color !important;
  line-height: 1rem !important;
  font-size: 12px;
  font-family: "Roboto Bold";
  width: 2.2rem !important;
  height: 2.2rem !important;
  margin: 2px 0 !important;
  display: flex;
  align-items: center;
  margin: 0 !important;
}
.btn-light:hover {
  background-color: white !important;
}

.ngb-dp-day:has(.custom-day.range.firstDate)  {
  background: linear-gradient( to right, #ffffff 0%, #ffffff 50%, $primary-color-rgba 50%, $primary-color-rgba 100% );
}
.ngb-dp-day:has(.custom-day.range.lastDate)  {
  background: linear-gradient( to left, #ffffff 0%, #ffffff 50%, $primary-color-rgba 50%, $primary-color-rgba 100% );
}
.ngb-dp-navigation-select {
  display: flex !important;
  flex: none !important;
  margin-left: -76px !important;
  height: 32px !important;
  width: 79px !important;
  font-family: "Roboto Bold";
  gap: 2px;
  .form-select {
    border: none;
    border-bottom: 1px solid $font-primary-color;
    border-radius: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
}

.btn-light.ng-star-inserted {
  width: 2.2rem !important;
  height: 2.2rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.7rem !important;
  border-radius: 0px;
  color: $font-primary-color !important;
  /*background-color: #f8f8f8 !important;*/
}
/*
.btn-light.ng-star-inserted:hover {
  background-color: #ffc19d !important;
}*/

.btn-light.bg-primary.text-white.ng-star-inserted {
  color: #ffffff !important;
  background-color: $secondary-color !important;
}

.btn-light.text-muted.outside.ng-star-inserted {
  color: #b7b7b7 !important;
}

#aiportOD .dropdown-menu,
#aiportOD2 .dropdown-menu {
  width: calc(100% - 30px);
  padding: 0px !important;
}

#aiportOD .dropdown-item,
#aiportOD2 .dropdown-item {
  background-image: url("images/icon-plane-select.png") !important;
  background-size: 27px 25px !important;
  background-repeat: no-repeat !important;
  background-position: 10px 10px !important;
  padding-left: 50px !important;
  display: block !important;
  color: $primary-color !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  border-bottom: #e3e4e6 solid 1px !important;
  cursor: pointer !important;
  font-family: "Roboto Regular" !important;
  font-size: 14px !important;
}

#aiportOD .dropdown-item:hover,
#aiportOD2 .dropdown-item:hover {
  background-color: #f6f6f6;
}

#aiportOD .dropdown-item.active,
.dropdown-item:active,
#aiportOD2 .dropdown-item.active,
.dropdown-item:active {
  background-color: #f6f6f6;
}

#aiportOD .dropdown-item:last-of-type,
#aiportOD2 .dropdown-item:last-of-type {
  border-bottom: 0px !important;
}

.form-group.hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2.2rem;
  width: 2.2rem;
  border: none;
  /*border: 1px solid;
  border-color: white;*/
  background-color: white;
  font-family: 'Roboto Bold';
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

#datepickerRange .dropdown-menu {
  transform: none !important;
  top: 38px !important;
  border: none !important;
}
.custom-day.faded:hover, .custom-day:not(.disabled):hover .btn-light:hover,  .custom-day:hover {
  background-color: #DEE0E3 !important;
  border-radius: 21px;
}
.custom-day.faded:active, .custom-day:not(.disabled):active .btn-light:active,  .custom-day:active {
  background-color: #CBCDD0 !important;
  border-radius: 21px;
}
.custom-day.range, .custom-day.firstDate, .custom-day.firstDate:hover, .custom-day.lastDate:hover, .custom-day.focused:not(.todayDate) {
  /*background-color: rgb(2, 117, 216);*/
  background-color: $secondary-color !important;
  color: white !important;
  border-radius: 21px;
  /*outline-offset: none !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;*/
}
.custom-day:focus-visible {
  outline: none !important;
}
.todayDate, .btn-light:focus,
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active {
  border-radius: 21px;
  border: 1px solid $secondary-color;
  background-color: transparent;
}
.todayDate:hover, .btn-light:hover,
.btn-light:not(:disabled):not(.disabled):hover, .btn-light:not(:disabled):not(.disabled).hover {
  background-color: $primary-color-rgba !important;
}
/*
.custom-day:hover {
  background-color: $secondary-color-rgba;
}
*/
.custom-day.faded {
  /*background-color: rgba(2, 117, 216, 0.5);*/
  //background-color: #f89b64;
  //background-color: $secondary-color-rgba;
  background-color: transparent !important;
  color: var(--primary-color) !important;
  font-weight: 400;
  border-radius: 0;
}
@-moz-document url-prefix() {
  ngb-datepicker-navigation-select .custom-select option {
    font-weight: bolder;
    font-family: "Roboto";
  }
}
.ngb-dp-day:has(.custom-day.range.faded) {
  background-color: $primary-color-rgba !important;
}
.custom-day.fadedAfter {
  /*background-color: rgba(2, 117, 216, 0.5);*/
  //background-color: #f89b64;
  background-color: $primary-color-rgba;
  color: var(--primary-color) !important;
  font-weight: 400;
  border-radius: 0;
}

.custom-day.disabled, .ngb-dp-day.disabled {
  color: #b7b7b7 !important;
  background-color: white !important;
  opacity: 0.5;
}

body .p-slider:not(.p-state-disabled) .p-slider-handle {
  background-color: $secondary-color !important;
  border-color: $secondary-color !important;
}

body .p-slider .p-slider-range {
  background-color: $primary-color !important;
}

body .p-slider {
  background-color: #ffffff !important;
}

body .p-slider .p-slider-handle {
  height: 1.4em !important;
  width: 1.4em !important;
}

body .p-slider .p-slider-handle:focus {
  box-shadow: 0 0 0 0em !important;
}

body .p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.7em !important;
}

.checkbox-center {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  border: $primary-color solid 1px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  min-width: 100%;
  background-color: $primary-color;
  -webkit-appearance: none;
  background-image: url("images/checkbox.png");
  transform: rotate(0deg);
  background-repeat: no-repeat;
  background-position: right;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
}

.checkbox-center.active {
  background-image: url("images/checkbox-active.png");
}

.checkbox-center.secundary {
  background-color: #f0f0f0;
  border: #f0f0f0 solid 1px;
  color: $primary-color;
}

.add_icon {
  position: initial;
  bottom: 97vh;
  right: 95px;
  /*background-color: #FFF !important;*/
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  top: 30px;
  font-weight: 700;
  /*color: #fff !important;*/
  box-shadow: none !important;
  transition: 0.35s ease-in-out;
  font-size: 25px;
  text-decoration: none;
  z-index: 99999;
  width: 29px;
  height: 29px;
  border-radius: 0px !important;
  background-image: url(images/add.png);
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 30px;
  margin-left: 30px;
}

.edit_icon {
  content: "";
  display: inline-block;
  width: 23px;
  height: 23px;
  background-image: url(images/edit.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 5px;
  cursor: pointer;
}

.trash_icon {
  content: "";
  display: inline-block;
  width: 19px;
  height: 25px;
  background-image: url(images/delete.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 5px;
  cursor: pointer;
}

.cancel_icon {
  content: "";
  display: inline-block;
  width: 23px;
  height: 23px;
  background-image: url(images/cancel_v2.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 19px;
  cursor: pointer;
}

.refresh_icon {
  content: "";
  display: inline-block;
  width: 23px;
  height: 23px;
  background-image: url(images/refresh.png);
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 5px;
  cursor: pointer;
}
.danger_icon {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 20px;
  cursor: pointer;
}

.addButton {
  margin-right: 0px;
  margin-left: auto;
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}

.p-tooltip .p-tooltip-text {
  background-color: $secondary-color !important;
  color: #fff !important;
}

.p-tooltip-top .p-tooltip-arrow {
  border-top-color: $secondary-color !important;
}

.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: $secondary-color !important;
}

.p-tooltip-left .p-tooltip-arrow {
  border-left-color: $secondary-color !important;
}

.p-tooltip-right .p-tooltip-arrow {
  border-right-color: $secondary-color !important;
}

.flight-details-tooltip {
  opacity: 1 !important;
  margin-left: 5px;
  .tooltip-inner {
    background-color: #fff;
    color: $font-primary-color;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 12px 16px;
    max-width: 100%;
    text-align: left;
    display: flex;
    flex-flow: column;
    gap: 12px;
  }
}

.flight-extra-information-tooltip {
  opacity: 1 !important;
  margin-left: 5px;
  .tooltip-inner {
    background-color: #fff;
    color: $font-primary-color;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 12px 16px;
    width: 229px;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Roboto Regular';
    text-align: left;
  }
}

.modal-content {
  font-family: "Roboto Regular" !important;
}

#menu-horizontal input:checked ~ ul {
  display: block;
}

.button.button-blue {
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: #fff;
  text-align: left;
  cursor: pointer;
  padding-right: 50px;
}

#cheapestFlights td {
  width: 120px;
  text-align: center;
}

#cheapestFlights td.title {
  background-color: $primary-color;
  color: white;
  font-size: 14px;
  text-align: right;
  margin-top: 2px;
  padding: 3px;
  white-space: nowrap;
}

#cheapestFlightsTable {
  border-collapse: separate;
  border-spacing: 4px 4px;
  margin-left: 5px;
}

#cheapestFlights td.price {
  background-color: white;
  color: $primary-color;
  font-size: 14px;
  text-align: center;
  margin-top: 2px;
  padding: 3px;
}

#cheapestFlights td.image {
  background-color: white;
  padding: 10px;
}
#cheapestFlights-container {
  border-top: 1px solid #fff;
  padding-top: 20px;
}
#cheapestFlights-container .cheapest {
  display: grid;
  /*grid-gap: 5px;*/
  grid-template-rows: 1fr;
  /*grid-template-columns: 100px repeat( auto-fit, minmax(200px, 1fr) );*/
  /*grid-template-columns: repeat(7, 1fr);*/
}
#cheapestFlights-container .cheapest .title:not(.emptyTitle) {
  justify-content: start;
  background-color: $primary-color;
  color: #fff;
  font-size: 13px;
  text-align: right;
  margin-top: 3px;
  margin-right: 3px;
  padding: 4px 4px 4px 10px;
  white-space: nowrap;
  font-weight: bold;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-transform: uppercase;
}
#cheapestFlights-container .cheapest > div {
  display: grid;
  justify-content: center;
  align-items: center;
}
#cheapestFlights-container .cheapest .item {
  background-color: white;
  text-align: center;
  margin-top: 3px;
  margin-right: 3px;
}
#cheapestFlights-container .cheapest .item.airlineImage {
  background-color: #f8f8f8;
}
#cheapestFlights-container .cheapest:not(:first-child) .item:hover {
  background-color: $secondary-color !important;
  color: white !important;
}
.priceLink {
  cursor: pointer;
}

body .p-dropdown {
  width: 100% !important;
  border-color: white !important;
  color: $font-placeholder-color !important;
  height: 39px !important;
}

body .p-dropdown .p-dropdown-trigger {
  color: $font-primary-color !important;
}
.groupList {
  .p-dropdown.p-component {
    color: $primary-color !important;
    border: 1px solid !important;
    border-color: #DEE0E3 !important;
    border-radius: 6px !important;
    .p-dropdown-label {
      padding-top: 1px;
      padding-bottom: 1px;
      border: none !important;
    }
  }
  .p-dropdown-trigger {
    .p-icon-wrapper {
      display: none !important;
    }
    &::before {
      background-image: url("../assets/images/expand_down.png") !important;
      width: 16px;
      height: 16px;
      background-size: 16px;
      display: inline-block;
      content:"";
    }
  }
}
/*body .p-dropdown .p-dropdown-trigger, body .p-dropdown, body .p-inputtext {
    background-color: #e3e4e6 !important;
}
body .isHome .p-dropdown .p-dropdown-trigger, body .isHome .p-dropdown, body .isHome .p-inputtext {
    background-color: #efefef !important;
}
body .p-dropdown {
    border: 1px solid $primary-color !important;
}*/
.p-dropdown-label-container {
  padding-left: 3px;
  padding-top: 6px;
}
/*.p-dropdown .p-dropdown-label.p-placeholder {
    letter-spacing: 1px;
}*/
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
  background-color: #f4a071 !important;
  background-color: $secondary-color-rgba !important;
}

.fa.fa-caret-down {
  font-size: 24px;
}

body .error .p-dropdown {
  border-color: red !important;
}

.hideAlt::after {
  display: none;
}

#btnGoToTop {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 26px;
  border: none;
  outline: none;
  background-color: $secondary-color;
  color: white;
  box-shadow: 1px 1px 23px 2px #888888;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
  font-weight: bold;
  width: 45px;
  height: 45px;
}

#bookingForm .button:disabled {
  cursor: no-drop;
}

input[type="search" i]::-webkit-search-cancel-button {
  margin-right: 40px !important;
}

/* ---------------- NEW ----------- */

.container.tabla {
  margin-top: 3em;
  margin-bottom: 3em;
  padding: 2em;
  padding-top: 1em;
  background-color: #fff;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
  overflow-x: scroll;
  font-family: "Poppins", Arial, sans-serif !important;
  font-size: 14px;
  line-height: 1.8;
  font-weight: normal;
  background: #fff;
  color: gray;
}


body .p-widget-overlay {
  background-color: rgba(0, 0, 0, 0) !important;
}

.p-widget-overlay.p-sidebar-mask {
  z-index: -10000 !important;
}

body .p-sidebar {
  /*background-image: url("images/fondo-sidebarPanelAdm.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: revert;*/
  background-color: #FFF;
  color: $primary-color;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: absolute;
  z-index: 5;
  height: fit-content;
}
.fecha::placeholder,
.hora::placeholder {
  color: $font-placeholder-color !important;
}

.cursor-pointer {
  cursor: pointer;
}
#flight-resume-box {
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  grid-auto-rows: max-content;
  width: 100%;
}
.airlineImage-flight-resume-box {
  float: right;
  grid-column: 3/4;
  grid-row: -1/4;
  justify-self: end;
  align-self: start;
}
#resultAirlineDuration {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  justify-content: end;
  margin-bottom: 0 !important;
}
#flightMoreDetails {
  //padding: 0 !important;
  flex-wrap: wrap;
  display: grid !important;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(max-content, 250px));
}
#flightMoreDetails div {
  //padding: 15px 15px 15px 0 !important;
  border-top: 0 !important;
  //border-bottom: #FFF solid 1px;
}
#bestPrices > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-sidebar-close {
  position: absolute !important;
  left: 30px !important;
  top: 30px !important;
  color: $secondary-color !important;
}
.fareResultsUnselected {
  background-color: #e3e4e6 !important;
  border-color: #e3e4e6 !important;
  color: $primary-color !important;
}
.imagesFlightBox {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  max-width: 100%;
  margin: 5px 0;
}
.imagesFlight {
  height: auto;
  width: 100%;
}
/*.imagesFlightBox .imagesFlight {
    margin-right: 2px;
}
.imagesFlightBox .imagesFlight.cheapest:not(:first-child) {
    margin-left: 2px;
}*/
.imageFlightLinea {
  min-width: 15px;
  width: 400px;
  height: 1px;
  max-width: 100%;
}
.transform-horizontal {
  transform: scaleX(-1);
}
.borderBox {
  border: #e3e4e6 solid 1px;
}

.pagination .page-item .page-link {
  color: var(--secondary-color) !important;
  background-color: white !important;
  border-color: #e9ecef !important;
}
.pagination .page-item.active .page-link {
  color: #fff !important;
  background-color: var(--secondary-color) !important;
  border-color: #e9ecef !important;
}
.pagination .page-item:nth-child(1) .page-link,
.pagination .page-item:nth-child(2) .page-link,
.pagination .page-item:last-child .page-link,
.pagination .page-item:nth-last-child(2) .page-link {
  color: var(--primary-color) !important;
  background-color: white !important;
  border-color: #e9ecef !important;
}

.selectLanguage {
  color: white;
  background-image: url("images/select2.png");
}
.selectLanguage option {
  color: var(--primary-color);
}
.login.button:hover {
  background-color: $primary-color !important;
}
.inputLoyaltyProgram .p-autocomplete-input {
  width: 100% !important;
  border: 0 !important;
  height: calc(1.5em + 0.75rem + 2px);
  border-radius: 5px;
}
#inputLoyaltyProgram .inputLoyaltyProgram .p-inputtext {
  font-family: "Roboto Regular" !important;
  //font-size: 1/px;
}
#inputLoyaltyProgram .p-autocomplete > .p-autocomplete-panel > .p-autocomplete-items > .p-highlight {
  background-color: $secondary-color !important;
  border: 1px solid #d0d0d0 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.inputstyles .p-multiselect {
  width: 100% !important;
  border-color: white !important;
  color: var(--primary-color) !important;
  height: 39px !important;
}
.inputstyles .p-multiselect .p-inputtext {
  margin-bottom: 0 !important;
}
.inputstyles .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
  width: 85% !important;
}
.inputstyles .p-multiselect .p-multiselect-trigger {
  color: var(--primary-color) !important;
}
.inputstyles .p-multiselect .p-multiselect-label-container {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}

.inputstyles .p-multiselect .p-multiselect-label,
.inputstyles .p-multiselect-panel .p-multiselect-item span {
  color: var(--primary-color) !important;
}

.inputstyles .p-autocomplete > .p-inputtext {
  background-color: #fff !important;
  display: block;
  width: 100%;
  padding: 10px !important;
  border-radius: 5px;
  border: 1px solid #fff;
  margin-bottom: 20px;
  overflow: visible;
  height: 40px;
  line-height: inherit;
}

#menuInputs #statusField .p-dropdown .p-dropdown-label, 
#menuInputs #gdsField .p-dropdown .p-dropdown-label, 
#menuInputs #agencyField .p-dropdown .p-dropdown-label {
  padding: 0px 56px 0px 11px !important;
  height: 100% !important;
  background-color: white !important;
  padding-top: 2px !important;
  font-size: 15px !important;
  color: #445C74 !important;
}
#menuInputs .p-dropdown .p-dropdown-label.p-placeholder {
  padding: 0 56px 0 0 !important;
  height: 100% !important;
}
#menuInputs .p-dropdown {
  height: 100% !important;
  /*border: 1px solid #DEE0E3 !important;
  border-radius: 0px 6px 6px 0px !important;*/
}
#menuInputs #statusField .p-dropdown .p-dropdown-label.p-placeholder, 
#menuInputs #gdsField .p-dropdown .p-dropdown-label.p-placeholder, 
#menuInputs #agencyField .p-dropdown .p-dropdown-label.p-placeholder {
  background-color: white !important;
  padding-top: 2px !important;
  color: #A1A9B8 !important;
  font-size: 15px !important;
}
#menuInputs #statusField .p-dropdown .p-dropdown-trigger, 
#menuInputs #gdsField .p-dropdown .p-dropdown-trigger, 
#menuInputs #agencyField .p-dropdown .p-dropdown-trigger {
  background-color: transparent !important;
}
#menuInputs #statusField .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon, 
#menuInputs #gdsField .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon,
#menuInputs #agencyField .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  padding-top: 0 !important;
}
#menuInputs #statusField .p-dropdown-label-container, 
#menuInputs #gdsField .p-dropdown-label-container, 
#menuInputs #agencyField .p-dropdown-label-container
{
  /*border: 1px solid #DEE0E3 !important;
  border-radius: 0px 6px 6px 0px !important;*/
  height: 100%;
}
#menuInputs .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-state-disabled):hover, 
#menuInputs .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group:not(.p-highlight):not(.p-state-disabled):hover
 {
  background-color: $primary-color-rgba !important;
  font-size: 15px !important;
  color: #445C74 !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
}
#menuInputs .p-dropdown-panel .p-dropdown-items .p-dropdown-item, 
#menuInputs .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  font-size: 15px !important;
  color: #445C74 !important;
  font-weight: 400 !important;
  padding: 2px 11px;
}
#menuInputs  p-dropdownitem li span {
  font-size: 15px !important;
  color: #445C74 !important;
  font-weight: 400 !important;
  font-family: 'Roboto Regular';
}
#menuInputs .p-dropdown-panel .p-dropdown-items .p-dropdown-item, 
#menuInputs .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group{
margin: 5px !important;
}
#menuInputs .ngb-dp-header::before {
  padding: 5px 14px !important;
}
.p-state-focus {
  box-shadow: none !important;
}
#menuInputs p-dropdown {
  height: 100% !important;
}
#menuInputs .p-dropdown {
  /*border: 1px solid #DEE0E3 !important;
  border-radius: 0px 6px 6px 0px !important;*/
  border: none !important;
}
.cursor-pointer {
  cursor: pointer;
}

#paginationListBooking a.page-link {
  border: 0 !important;
  background: #FFFFFF !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16) !important;
  border-radius: 6px !important;
  margin: 2px !important;
  font-size: 14px !important;
}
#paginationListBooking .pagination .page-item .page-link {
  color: #464F60 !important;
  font-weight: 500 !important;
  padding: 7px 12px !important;
  min-width: 32px !important;
  height: 32px !important;
  line-height: initial !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
#paginationListBooking .pagination .page-item:nth-child(1) .page-link,
#paginationListBooking .pagination .page-item:nth-child(2) .page-link,
#paginationListBooking .pagination .page-item:last-child .page-link,
#paginationListBooking .pagination .page-item:nth-last-child(2) .page-link {
  padding: 7px !important;
}
#paginationListBooking .pagination .page-item:nth-child(2) .page-link{
  margin-right: 10px !important;
}
#paginationListBooking .pagination .page-item:nth-last-child(2) .page-link {
  margin-left: 10px !important;
}
#paginationListBooking .pagination .page-item.active .page-link {
  color: white !important;
  background-color: $secondary-color !important;
  border: 0 !important;
}

.modal-content {
  background: #FFFFFF !important;
  box-shadow: 0px 0px 0px 1px rgba(152, 161, 178, 0.1), 0px 30px 70px -10px rgba(17, 24, 38, 0.25), 0px 10px 30px rgba(0, 0, 0, 0.2) !important;
  border-radius: 12px !important;
}

#alertMessages .close {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  height: min-content !important;
  line-height: 1rem !important;
}
#alertMessages{
  border: none !important;
  width: fit-content;position: fixed;bottom: 0;left: 50%;transform: translate(-50%, -50%);font-family: 'Roboto Regular';background-color: #AA5B00 !important;color: #FCF2E6 !important;
  background: #AA5B00;
  box-shadow: 0px 0px 0px 0.5px rgba(255, 255, 255, 0), 0px 15px 35px -5px rgba(17, 24, 38, 0.5), 0px 5px 15px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.form-control:-moz-focusring {
  text-shadow: none !important;
 }
 #menuInputs .fa-caret-down:before {
  content: "" !important;
  background-image: url("images/arrow_down_2.png") !important;
  width: 20px !important;
  display: block !important;
  height: 25px !important;
  background-repeat: no-repeat !important;
  background-position: 50% 50% !important;
}
.fareRuleGroups .p-inputtext:enabled:focus:not(.p-state-error) {
  box-shadow: none !important;
  border: 0 !important;
}

.p-sidebar-header, .p-sidebar-footer {
  padding: 0px !important;
}

.modal {
  z-index: 1050 !important;
}

.modal-backdrop {
  z-index: 1040 !important;
}

.p-dropdown {
  align-items: center !important;
  box-shadow: none !important;
}
.p-autocomplete input:not(.error) {
  border: none !important;
}
.p-autocomplete input {
  width: 100% !important;
}

.p-autocomplete input:focus {
  box-shadow: none !important;
}
.visually-hidden {
  display: none;
}

.booking-list-dropdown {
  color: #464F60 !important;
  width: 250px !important;
}

.booking-list-dropdown-bold {
  color: #464F60 !important;
  width: 250px !important;
  border: 2px solid #DEE0E3 !important;
  #shared-dropdown-button {
    font-weight: bold !important;
  }
}

.booking-list-dropdown-filter {
  color: #464F60 !important;
}

.datatable-dropdown-filter {
  color: #464F60 !important;
}

.clouds-background {
  background-image: url(../assets/images/clouds_background.jpg);
  background-size: cover;
  background-position: center;
  height: 100%;
  background-attachment: fixed;
}

.grey-background {
  background-color: #F8F8F8;
}

.white-background {
  background-color: #fff;
}

.fare-carrousel {
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-auto-flow: column;
  background-color: #F8F8F8;
  gap: 16px;
  padding: 16px 8px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

#preferredAirlinesInput::placeholder, #typeahead-focus::placeholder {
  color: $font-primary-color;
}

#date-section .dropdown-menu {
  margin-left: -30px !important;
  @media (min-width: 335px) {
    margin-left: -15px !important;
  }
  @media (min-width: 350px) {
    margin-left: 0px !important;
  }
}

/* NEW COMPONENTS*/

/* BUTTONS */

@mixin button-style($background-color, $border-color, $font-color) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bolder;
  color: $font-color;
  border-radius: 8px;
  background-color: $background-color;
  border: 2px solid $border-color;
  cursor: pointer;
  width: 100%;
  padding: 8px 16px;
  transition: 0.15s;
  & > img {
    width: 24px;
  }
}

.button-secondary {
  @include button-style($secondary-color, transparent, #fff);
  &:hover {
    @include button-style($secondary-color, $primary-color, #fff);
  }
  &.button-disabled, &.button-disabled {
    cursor: default;
    background-color: #F3D3BF;
  }
}

.button-gray {
  @include button-style(#8B96A0, #8B96A0, #fff);
  &:hover {
    @include button-style(#8B96A0, $primary-color, #fff);
  }
}

.button-black {
  @include button-style(#fff, #E3E4E6, #464F60);
  &:hover {
    @include button-style(#fff, $primary-color, #464F60);
  }
}

.button-white {
  @include button-style(#fff, #E3E4E6, $font-primary-color);
  &:hover {
    @include button-style(#fff, $primary-color, $font-primary-color);
  }
  & > img {
    filter: $image-color2;
  }
  &.button-disabled {
    @include button-style(#F8F8F8, $font-disabled-color, $font-disabled-color);
    &:hover {
      @include button-style(#F8F8F8, $font-disabled-color, $font-disabled-color);
    }
    cursor: default !important;
    & > img {
      filter: $image-disabled-color;
    }
  }
}

.button-white-primary {
  @include button-style(#fff, $primary-color, $primary-color);
  &:hover {
    @include button-style(#fdfdfd, $primary-color, $primary-color);
  }
  & > img {
    filter: $image-primary-color;
  }
}

.button-white-secondary {
  @include button-style(#fff, transparent, $secondary-color);
  &:hover {
    @include button-style(#fff, $secondary-color, $secondary-color);
  }
}

.button-thin {
  padding: 8px 12px !important;
}

.button-fit-content {
  max-width: fit-content !important;
}

/* CHECKBOXS */

@mixin checkbox-style($color, $checked-color) {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  cursor: pointer;
  gap: 16px;
  label {
    color: #fff;
    cursor: pointer;
    margin-bottom: 0px !important;
  }
  input[type="checkbox"] {
    position: relative;
    margin-bottom: 0px;
    background-image: none;
    border: 1px solid lightgrey;
    width: 18px;
    height: 18px;
    background-color: white;
    border-radius: 4px;
    transition: 0.15s;
    margin-right: 0px !important;
  }
  &:hover input[type="checkbox"] {
    border: 1px solid $color;
  }
  input[type="checkbox"]:checked {
    background-image: none;
    border: 1px solid $color;
    &:after {
      content: "";
      background: url('../assets/images/checkbox_black.png');
      filter: $checked-color;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18px;
      height: 18px;
    }
  }
}
.checkbox-secondary {
  @include checkbox-style($secondary-color, $image-color);
  label {
    color: $font-primary-color;
  }
}

.checkbox-secondary-bold {
  @include checkbox-style($secondary-color, $image-color);
  label {
    font-weight: bold;
  }
}

.checkbox-secondary-8 {
  @include checkbox-style($secondary-color, $image-color);
  gap: 8px;
  label {
    color: $font-primary-color;
  }
}

.checkbox-secondary-8-big {
  @include checkbox-style($secondary-color, $image-color);
  gap: 8px;
  label {
    font-size: 16px;
    line-height: 20px;
    color: $font-primary-color;
  }
}

/* ICONS */

@mixin x-icon-style($color) {
  position: relative;
  width: 24px;
  height: 24px;
  transition: 0.15s;
  padding: 10px;
  border-radius: 100%;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #E3E4E6;
  }
  &::after {
    content: "";
    background: url('../assets/images/close3.png');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    filter: $color;
  }
}

.x-icon-primary {
  @include x-icon-style($image-color2);
}

/* TEXT FIELDS */

.text-field {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: white;
  padding: 0px 10px !important;
  border: 1px solid white;
  border-radius: 4px;
  min-height: 40px;
  &:hover, &.text-field-content {
    border: 1px solid $primary-color;
  }
  &.text-field-focus {
    outline: 1px solid $primary-color;
  }
  &.text-field-error {
    border: 1px solid #D1293D;
    &::after {
      content: "";
      background-image: url('../assets/images/error.png');
      background-size: contain;
      background-repeat: no-repeat;
      display: block;
      width: 24px;
      height: 24px;
      z-index: 1;
    }
  }
  & > img {
    width: 24px;
    filter: $image-color2;
  }
  & > input[type="text"] {
    font-size: 14px;
    line-height: 20px;
    padding: 0px !important;
    margin: 0px !important;
    width: 100%;
    height: 100%;
    &:focus {
      box-shadow: none;
    }
  }
  .x-icon-primary {
    display: none; 
   }
  &:hover .x-icon-primary {
   display: block;
   position: absolute;
   right: 10px;
   top: 20%;
  }
  input::placeholder {
    color: $font-placeholder-color;
    font-family: 'Roboto Regular';
  }
  input:-moz-placeholder {
    color: $font-placeholder-color;
    font-family: 'Roboto Regular';
    opacity:  1;
  }
 input::-moz-placeholder {
    color: $font-placeholder-color;
    font-family: 'Roboto Regular';
    opacity:  1;
  } 
  .input-14-bold {
    font-size: 14px;
    line-height: 20px;
  }
  .form-control {
    width: 100%;
  }
  .date-input {
    font-size: 14px;
    line-height: 20px;
  }
  p-autoComplete {
    width: 100%;
    &.placeholder-bold span.p-autocomplete > .p-inputtext::placeholder {
      color: $font-primary-color;
    }
    span.p-autocomplete {
      position: initial;
      margin-bottom: 0px;
      & > .p-inputtext {
        background-color: transparent !important;
        font-size: 14px;
        line-height: 20px;
        padding-right: 0px !important;
        padding-left: 0px !important;
        height: 40px;
        &::-webkit-search-cancel-button {
          display: none;
        }
        &::placeholder {
          color: $font-placeholder-color !important;
        }
      }
      .p-overlay {
        left: 0px !important;
        width: 100%;
      }
      .p-autocomplete-panel {
        color: $font-primary-color !important;
        font-style: normal !important;
        font-family: "Roboto Bold";
        border-radius: 16px;
        padding: 15px 0px;
      }
    }
  }
  p-dropdown {
    width: 100%;
    .p-dropdown-trigger {
      display: none;
    }
    .p-dropdown {
      height: auto !important;
    }
    .p-dropdown-label {
      font-size: 14px;
      line-height: 20px;
      white-space: nowrap;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .p-placeholder {
      width: 10px !important;
      font-size: 14px !important;
    }
    .p-dropdown-clear-icon {
      right: 1rem;
      display: none;
    }
  }
  .selectMultiple + .dropdown-menu {
    //margin-left: -42px !important;
    //margin-top: 8px !important;
    width: 90% !important;
    border-radius: 16px !important;
    border: 1px solid #c8c8c8 !important;
    box-shadow: 0 3px 6px #00000029 !important;
    padding: 15px 0px !important;
  }
  input[type="text"], .hora {
    margin-bottom: 0px;
    width: 100%;
    border: none !important;
  }
}

/* TABLE */
.custom-table-wrapper {
  font-family: 'Roboto Regular';
  display: flex;
  flex-direction: column;
  gap: 50px;
  overflow-x: auto;
  padding: 0px !important;
  .custom-table-header {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .custom-table-search {
      width: 100%;
      form {
        width: 100%;
        .text-field {
          border-radius: 6px;
          border: 1px solid #C9CED8;
          box-shadow: 0px 0px 0px 1px rgba(132, 143, 162, 0.40), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
          &.with-length {
            border: 2px solid #FAC3A0 !important;
            box-shadow: none !important;
          }
        }
      }
    }
  }
  .custom-table {
    overflow-x: auto;
    max-width: -webkit-fill-available;
    max-width: -moz-available;
    width: 100%;
    border: 0px;
    margin-bottom: 50px;
    .checkbox-secondary {
      gap: 0px !important;
      margin-bottom: 0px !important;
    }
    .actionsImage {
      background-image: url("../assets/images/actions_btn.png");
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: 50%;
      cursor: pointer;
    }
    .actionsImageBold {
      background-image: url("../assets/images/actions_btn_hover.png") !important;
    }
    .actionsImage:hover {
      background-color: #FFE8DB !important;
      border-radius: 4px !important;
      background-image: url("../assets/images/actions_btn_hover.png") !important;
    }
    .actionsImageMenu {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: left;
      background-color: #fff;
      box-shadow: 0 0 0 1px #98a1b31a, 0 15px 35px -5px #11182626, 0 5px 15px #00000014;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      min-width: 100px;
      padding: 8px;
      z-index: 1;
      & > div {
        display: flex;
        gap: 8px;
        align-items: center;
        cursor: pointer;
        &.edit-action {
          color: #5a6376;
        }
        &.delete-action {
          color: #d1293d;
        }
        img {
          width: 16px;
        }
      }
    }
    thead {
      #multipleMenu {
        border: 1px solid #DEE0E3;
        border-radius: 6px;
        background-color: white;
        padding: 6px 12px;
        display: inline-flex;
        gap: 16px;
        align-items: center;
      }
      #multipleMenu > div {
        display: contents;
        cursor: pointer;
      }
      #multipleMenu img {
        width: 16px;
      }
      #multipleMenuLine {
        width: 1px;
        height: 17px;
        border: 1px solid #D5DBE5;
      }
      th {
        background-color: #E9EDF5;
        text-align: left;
        padding: 8px 10px;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1px;
        vertical-align: middle;
        text-transform: uppercase;
        border-bottom: 1px solid #D5DBE5;
        position: relative;
  
        span {
          vertical-align: text-bottom;
        }
      }
    }
    tbody {
      border: 0;
      tr {
        td {
          height: 64px;
          padding: 12px 10px;
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.25px;
          border: 0px;
          border-top: 1px solid #DEE0E3; 
          vertical-align: middle;
          position: relative;
        }
        &:nth-child(2n) {
          background-color: #F7F9FC;
        }
        &:last-child td {
          border-bottom: 1px solid #DEE0E3;
        }
      }
    } 
  }
  .custom-table-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    input[type="number"] {
      font-family: 'Roboto Regular';
      font-size: 13px;
      line-height: 16px;
    }
    & > div {
      display: flex;
      align-items: center;
      label {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
      }
      input {
        margin-bottom: 0px;
        width: 70px;
        border: 1px solid #DEE0E3;
        padding: 5px;
      }
    }
    #previousNextButtons {
      & > div {
        width: auto;
        padding: 12px 10px;
        height: 40px;
        & > img {
          width: 16px;
          &:not(.last-page) {
            filter: invert(100%);
          }
        }
        &.last-page {
          background-color: #fff !important;
          border: 2px solid #DEE0E3 !important;
          span {
            display: none;
          }
          img {
            filter: $image-color2;
          }
        }
      }
    }
  }
}


.customInputLoyaltyProgram {
  width: 100% !important;
  border-radius: 4px !important;
  background: #F8F8F8 !important;
  color: $primary-color !important;
  font-family: 'Roboto Regular' !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 20px !important;
  margin: 0 !important;
  height: 42px !important;
  .p-inputtext {
    height: 42px !important;
    font-family: 'Roboto Regular' !important;
    font-size: 14px;
    font-style: normal !important;
    font-weight: 400;
    line-height: 20px;
    background-color: #F8F8F8;
    width: 100%;
    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
  }
}

.custom-dropdown .p-dropdown {
  background-color: #F8F8F8 !important;
  height: 42px !important;
}

.frecuently-passenger-edit-row .custom-dropdown {
  .p-dropdown {
    height: 40px !important;
  }
  .p-dropdown-label {
    font-size: 14px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
  }
  .p-dropdown-item {
    font-size: 14px !important;
  }
}


.modal-dialog:has(.templateFrequentClientSavedTitle), .modal-dialog:has(.templateCancelBooking){
  width: max-content !important;
}
#languageDropdown {
  .p-overlay {
    right: 0 !important;
    left: auto !important;
  }
  &:hover, &:active {
    border-radius: 100px !important;
    background-color: #F8F8F8 !important;
  }
  &:focus {
    border-radius: 100px !important;
    background-color: #E3E4E6 !important;
  }
  .p-dropdown {
    background-color: transparent !important;
    border: none !important;
    height: max-content !important;
    display: inline-flex !important;
    padding: 12px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 4px !important;
  }
  .p-dropdown-label {
    color: $font-primary-color !important;
    font-family: "Roboto Regular" !important;
    font-size: 16px !important;
    font-style: normal !important;
    line-height: 20px !important;
    padding: 0 !important;
  }
  .p-dropdown .p-dropdown-trigger {
    width: max-content !important;
    margin-left: 6px !important;
  }
  .p-dropdown-open > .p-dropdown-trigger {
    transform: rotate(-180deg) !important;
  }
  .p-dropdown-panel {
    display: flex !important;
    width: 119px !important;
    padding: 8px !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 5px !important;
    border-radius: 16px !important;
    background: #FFF !important;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10) !important;
    .p-dropdown-items-wrapper, .p-dropdown-items-wrapper > ul {
      width: 100% !important;
    }
    .p-dropdown-item {
      display: flex !important;
      padding: 12px 16px !important;
      align-items: center !important;
      gap: 8px !important;
      align-self: stretch !important;
      border-radius: 10px !important;
      font-family: "Roboto Regular" !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 20px !important;
      &.p-highlight {
        background:  #F8F8F8 !important;
        color: #0E0F0C !important;
      }
    }
    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      background: #FFF !important;
      color: $primary-color !important;
    }
  }
}
.p-sidebar .p-sidebar-content {
  position: fixed;
  height: 100%;
  width: inherit;
  padding: 0px !important;
  background-color: #fff;
  #sidebar-header {
    width: 300px;
    padding: 24px 16px !important;
    background-color: #fff;
    height: 100%;
    .ngb-dp-months {
      flex-direction: column !important;
    }
  }
  .list-unstyled {
    margin-left: 0 !important;
    margin-top: 8px !important;
    margin-right: 0 !important;
    display: flex;
    flex-flow: column;
    gap: 8px;
  }
}
.sectionData {

  .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    background-color: white !important;
    color: $primary-color !important;
  }
  .p-inputtext {
    padding-left: 5px;
    color: $primary-color !important;
    font-family: 'Roboto Regular' !important;
    font-size: 14px;
    font-style: normal !important;
    line-height: 20px;
    padding-left: 10px !important;

    .error {
      @extend .error;
    }
  }
  .p-inputtext::placeholder {
    color: $font-placeholder-color !important;
  }
  .p-autocomplete>.p-inputtext {
    padding-left: 10px !important;
  }
}

.hideElement{
  display: none !important;
}

.create-button {
  font-family: 'Roboto Regular' !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 40px !important;
  width: 200px !important;
  float: right !important;
  margin-bottom: 15px !important;
}

.custom-form {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  .custom-form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .custom-form-title {
      font-size: 24px;
      line-height: 32px;
      padding: 12px 0px;
    }
    .custom-form-panel {
      padding: 24px;
      background-color: #F7F9FC;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      @media (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      &.one-column {
        grid-template-columns: 1fr;
      }
      .custom-form-input {
        display: flex;
        flex-direction: column;
        gap: 4px;
        & > div:first-child {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1px;
          padding: 8px 0px;
        }
        input, textarea {
          font-size: 14px;
          margin-bottom: 0px;
        }
        input[type="text"]:not(.p-inputtext), .text-field, textarea {
          border: 1px solid #ced4da;
          &:focus {
            border-color: $primary-color;
          }
        }
        .tagWrapperAirline {
          .selectMultiple {
            padding-left: 9px !important;
          }
          .selected-item {
            display: flex;
            height: 32px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: #FFF;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            background-color: $secondary-color;
            font-family: 'Roboto Regular';
            display: flex;
            padding: 10px 12px;
            margin: 3px 2px 2px;
            border-radius: 8px;
          }
        }
      }
      .two-columns {
        @media (min-width: 768px) {
          grid-column: 1/3;
        }
      }
      .custom-form-input-checkboxs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 32px;
        @media (min-width: 768px) {
          grid-column: 1/3;
        }
      }
      .trash{
        filter: $image-color;
        margin-left: 24px;
        cursor: pointer;
      }
      .addButton img {
        filter: $image-color;
      }

      .addButton {
        display: flex;
        padding: 10px 16px 10px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: $secondary-color;
        text-align: center;
        font-family: 'Roboto Regular';
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; 
        margin: 0;
      }
    }
    .min-width-150 {
      min-width: 150px;
    }
  }
  .custom-form-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }
}

.custom-switch {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px;
  .p-inputswitch .p-inputswitch-slider:before {
    background-color: #ffffff !important;
  }
  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: $secondary-color !important;
  }  
}