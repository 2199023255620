@media (max-width: 1507px) {
  #flightMoreDetails div {
    display: auto;
    flex: auto;
    padding: auto;
    border-top: auto;
    border-bottom: auto;
  }
}

@media (max-width: 1262px) {
  .contraste {
    background-color: #93c; /*Morado*/
  }

  #flightMoreDetails {
    display: flex;
    flex-wrap: wrap;
    padding: 0px !important;
  }
  #flightMoreDetails div {
    flex: 1 0 21% !important;
    padding: 10px !important;
    border-top: 0 !important;
    border-bottom: #fff solid 1px;
  }
}

@media (max-width: 993px) {
  .contraste {
    background-color: #6f6; /*Verde*/
  }

  .copyright {
    position: static;
    bottom: 0px;
    width: 100%;
    color: #769bbd !important;
    letter-spacing: 1px;
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .box-secundary {
    background-color: #e3e4e6;
    height: auto;
    min-height: 282px !important;
    color: #2d4e89;
    width: 100%;
    padding-top: 40px;
  }

  .tab-info input[type="checkbox"] {
    display: inline-block;
    width: 15px;
    height: 15px;
    -webkit-appearance: none;
    background-image: url(images/checkbox-list.png);
    background-size: contain;
    transform: rotate(0deg);
    background-repeat: no-repeat;
    background-position: right;
    cursor: pointer;
    margin-bottom: -3px;
    margin-left: 10px;
  }

  .price {
    width: 150px;
  }

  .payment-resume {
    display: block !important;
  }

  .payment-resume div {
    text-align: right !important;
    display: block;
    margin-bottom: 20px;
    width: 100% !important;
  }
  #resultAirlineDuration div {
    flex: auto;
  }
}

@media (max-width: 782px) {
  #resultAirlineDuration div {
    flex: 1 0 0 !important;
  }
  .contraste {
    background-color: #ccc; /*Gris*/
  }

  .no-flex {
    display: block !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .no-flex.tab-info {
    padding-left: 0px;
    padding-right: 0px;
  }
  .no-flex.tab-info div {
    border-right: none !important;
    text-align: left !important;
    margin-left: 0px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .tabpanel .tab-info div:last-child {
    border-top: #fff solid 1px;
    margin-left: 0px !important;
  }

  .m-left {
    text-align: left !important;
  }

  .no-float {
    float: none !important;
  }

  .m-right {
    text-align: right !important;
  }

  .flight-resume .no-flex div {
    margin-bottom: 20px;
    margin-left: 0px !important;
  }

  .n-padding {
    padding-left: 0px;
    padding-right: 0px;
  }

  .n-float {
    float: none;
    text-align: left !important;
    width: 100% !important;
    padding-left: 20px !important;
    padding-bottom: 30px !important;
  }

  .tabpanel .no-flex div {
    width: 100% !important;
  }

  .box-secundary {
    min-height: auto !important;
    margin-bottom: 20px !important;
  }

  .list-01 {
    padding-bottom: 0px !important;
  }

  .list-02 {
    padding-top: 0px !important;
  }
  .copyright {
    position: static !important;
    margin-top: 50px;
  }

  .copyright a.lnk {
    margin-left: 0px;
    display: block;
  }

  .box-info .tt {
    padding-bottom: 40px !important;
  }

  .b-group .button {
    width: 100%;
    margin-bottom: 20px;
    display: block;
    margin-top: 20px;
  }

  /*.hora {
    text-align: left;
    padding-left: 30px !important;
    max-width: 150px;
    background-image: url(images/time.png);
    background-repeat: no-repeat;
    background-position: 4px !important;
}*/
  .social {
    float: right;
    height: 20px;
    margin-top: 5px;
    position: absolute;
    right: 0px;
    margin-top: -60px !important;
  }

  .animation {
    opacity: 1 !important;
  }
  .box-resume div.w-25 {
    float: left;
    width: 50% !important;
  }
  .tabpanel .tab-info {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .tabpanel .tab-info div:last-child {
    padding-top: 20px !important;
  }

  .fs11 {
    font-size: 12px !important;
  }

  .tabpanel .tab-info div {
    border-right: #fff solid 1px;
    padding-top: 0px !important;
    padding-right: 20px;
    padding-bottom: 0px !important;
    padding-left: 20px;
    flex: 1 1 auto !important;
  }
}

@media (max-width: 380px) {
  .contraste {
    background-color: #ffc; /*Amarillo*/
  }
}
