/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap";
//@import "./assets/style";
@import "./assets/css/fs-style";
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$third-color: var(--third-color);
$font-primary-color: var(--font-primary-color);
$font-secondary-color: var(--font-secondary-color);
$font-placeholder-color: var(--font-placeholder-color);
$font-disabled-color: var(--font-disabled-color);
$font-links-color: var(--font-links-color);

.no-overflow {
  @media (max-width: 599px) {
    overflow-y: hidden;
  }
}

.list-action {
  margin: 8px;
  &:hover {
    border-bottom: 1px black double;
  }
}

.regular {
  font-family: "Roboto Regular";
}
.regularThin {
  font-family: "Roboto Thin";
}
.regularBold {
  font-family: "Roboto Bold";
}
.regularBolder,
.regular .bolder {
  font-family: "Roboto Bolder";
}
.regularLight {
  font-family: "Roboto Light";
}

.textColorSecondary {
  color: $font-secondary-color !important;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: $font-primary-color !important;
  @extend .fs16;
  @extend .regular;
}
.p-dropdown .p-dropdown-label {
  color: $font-primary-color !important;
  @extend .fs16;
  @extend .regular;
}

p-dropdownitem li span {
  color: $font-primary-color !important;
  @extend .fs16;
  @extend .regular;
}

.p-autocomplete > .p-inputtext {
  @extend .fs16;
  @extend .regular;
}

#gdsField .p-dropdown .p-dropdown-clear-icon,
#statusField .p-dropdown .p-dropdown-clear-icon,
#agencyField .p-dropdown .p-dropdown-clear-icon {
  top: 58% !important;
}
#statusField .p-dropdown .p-dropdown-trigger,
#gdsField .p-dropdown .p-dropdown-trigger,
#agencyField .p-dropdown .p-dropdown-trigger {
  background: #f4f3f3 !important;
}
#statusField .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon,
#gdsField .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon,
#agencyField .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  padding-top: 3px !important;
}

#statusField .p-dropdown .p-dropdown-label,
#gdsField .p-dropdown .p-dropdown-label,
#agencyField .p-dropdown .p-dropdown-label {
  color: $font-primary-color !important;
  height: 43px;
  padding-top: 9px;
  padding-left: 11px;
  background: #f4f3f3 !important;
  @extend .fs16;
  @extend .regular;
}
#statusField .p-dropdown-label-container,
#gdsField .p-dropdown-label-container,
#agencyField .p-dropdown-label-container {
  padding: 0;
}
#statusField .p-dropdown.p-widget,
#gdsField .p-dropdown.p-widget,
#agencyField .p-dropdown.p-widget {
  min-width: auto !important;
}

.no-highlight .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: transparent !important;
}
#contactSection .p-dropdown-label {
  color: $font-primary-color !important;
  font-family: 'Roboto Regular' !important;
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 20px !important;
}

/* CUSTOM TOOLTIP */
.customTooltip {
  &.p-tooltip .p-tooltip-text {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15) !important;
    border-radius: 8px !important;
    padding: 12px 16px !important;
    font-family: Manrope !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 16px !important;
  }
  &.tooltipBlack {
    &.p-tooltip .p-tooltip-text {
      background-color: #23232A !important;
      color: #fff !important;
    }
    &.p-tooltip-top .p-tooltip-arrow {
      border-top-color: #23232A !important;
    }
    &.p-tooltip-bottom .p-tooltip-arrow {
      border-bottom-color: #23232A !important;
    }
    &.p-tooltip-left .p-tooltip-arrow {
      border-left-color: #23232A !important;
    }
    &.p-tooltip-right .p-tooltip-arrow {
      border-right-color: #23232A !important;
    }
  }
  &.tooltipWhite {
    &.p-tooltip .p-tooltip-text {
      background-color: #fff !important;
      color: #23232C !important;
    }
    &.p-tooltip-top .p-tooltip-arrow {
      border-top-color: #fff !important;
    }
    &.p-tooltip-bottom .p-tooltip-arrow {
      border-bottom-color: #fff !important;
    }
    &.p-tooltip-left .p-tooltip-arrow {
      border-left-color: #fff !important;
    }
    &.p-tooltip-right .p-tooltip-arrow {
      border-right-color: #fff !important;
    }
  }
  &.tooltipWhite2 {
    &.p-tooltip .p-tooltip-text {
      background-color: #fff !important;
      font-size: 14px !important;
      line-height: 20px !important;
      color: $font-primary-color !important;
      font-family: Roboto Regular !important;
    }
    &.p-tooltip-top .p-tooltip-arrow {
      border-top-color: #fff !important;
    }
    &.p-tooltip-bottom .p-tooltip-arrow {
      border-bottom-color: #fff !important;
    }
    &.p-tooltip-left .p-tooltip-arrow {
      border-left-color: #fff !important;
    }
    &.p-tooltip-right .p-tooltip-arrow {
      border-right-color: #fff !important;
    }
  }
}



#agencyField {
  input[type=text] {
    margin: 0 !important;
  }
  .p-icon-wrapper {
    position: absolute !important;
    top: 50% !important;
    right: 0 !important;
  }
}


@media (max-width: 1199px) {
  #miniButtons {
    display: flex !important;
  }
  #flight-search-box {
    margin-bottom: 0 !important;
  }
  #flight-search-box-header-img {
    display: none;
  }

  #flight-search-box #flight-search-box-header img#flight-search-box-header-img-close, #flight-filter-box-header-img-close {
    display: block !important;
  }
}
@media (min-width: 1200px){
  #flight-search-box-header-img-close, #flight-filter-box-header-img-close {
    display: none;
  }
}

.no-scroll {
  overflow: hidden;
}