/* roboto-100 - latin */
@font-face {
  font-family: "Roboto Thin";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/roboto-v29-latin-100.eot"); /* IE9 Compat Modes */
  src: local(""), url("../assets/fonts/roboto-v29-latin-100.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/roboto-v29-latin-100.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/roboto-v29-latin-100.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/roboto-v29-latin-100.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/roboto-v29-latin-100.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-100italic - latin */
@font-face {
  font-family: "Roboto Thin Italic";
  font-style: italic;
  font-weight: 100;
  src: url("../assets/fonts/roboto-v29-latin-100italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../assets/fonts/roboto-v29-latin-100italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/roboto-v29-latin-100italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/roboto-v29-latin-100italic.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/roboto-v29-latin-100italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/roboto-v29-latin-100italic.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/roboto-v29-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("../assets/fonts/roboto-v29-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/roboto-v29-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/roboto-v29-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/roboto-v29-latin-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/roboto-v29-latin-regular.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-family: "Roboto Italic";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/roboto-v29-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../assets/fonts/roboto-v29-latin-italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/roboto-v29-latin-italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/roboto-v29-latin-italic.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/roboto-v29-latin-italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/roboto-v29-latin-italic.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto Bolder";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/roboto-v29-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("../assets/fonts/roboto-v29-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/roboto-v29-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/roboto-v29-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/roboto-v29-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/roboto-v29-latin-700.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-700italic - latin */
@font-face {
  font-family: "Roboto Bolder Italic";
  font-style: italic;
  font-weight: 700;
  src: url("../assets/fonts/roboto-v29-latin-700italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../assets/fonts/roboto-v29-latin-700italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/roboto-v29-latin-700italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/roboto-v29-latin-700italic.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/roboto-v29-latin-700italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/roboto-v29-latin-700italic.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/roboto-v29-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""), url("../assets/fonts/roboto-v29-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/roboto-v29-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/roboto-v29-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/roboto-v29-latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/roboto-v29-latin-500.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-500italic - latin */
@font-face {
  font-family: "Roboto Bold Italic";
  font-style: italic;
  font-weight: 500;
  src: url("../assets/fonts/roboto-v29-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../assets/fonts/roboto-v29-latin-500italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/roboto-v29-latin-500italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/roboto-v29-latin-500italic.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/roboto-v29-latin-500italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/roboto-v29-latin-500italic.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/roboto-v29-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("../assets/fonts/roboto-v29-latin-300.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/roboto-v29-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/roboto-v29-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/roboto-v29-latin-300.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/roboto-v29-latin-300.svg#Roboto") format("svg"); /* Legacy iOS */
}
/* roboto-300italic - latin */
@font-face {
  font-family: "Roboto Light Italic";
  font-style: italic;
  font-weight: 300;
  src: url("../assets/fonts/roboto-v29-latin-300italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("../assets/fonts/roboto-v29-latin-300italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/roboto-v29-latin-300italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/roboto-v29-latin-300italic.woff") format("woff"),
    /* Modern Browsers */ url("../assets/fonts/roboto-v29-latin-300italic.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../assets/fonts/roboto-v29-latin-300italic.svg#Roboto") format("svg"); /* Legacy iOS */
}
